import React from 'react'
import snapchat from '../../assets/snapchat-color.svg'
import bereal from '../../assets/bereal-color.svg' 
import tvtime from '../../assets/tvtime-color.svg' 
import instagram from '../../assets/instagram-color.svg' 


const SocialItem = (props) =>{
  return(
      <a className='container max-w-24 flex justify-center justify-items-center aspect-square p-2 rounded-2xl sm:rounded-3xl sm:p-4 bg-stone-200 dark:bg-stone-400' href={props.social}> <img className='max-w-12' src={props.src} alt={props.name}/></a>      
  )
}

export const Social = () => {
  let socials = ['Instagram', 'Snapchat', 'Bereal', 'TvTime']
  let social_srcs = [instagram, snapchat, bereal, tvtime]
  let social_urls = [
    "https://www.instagram.com/notalepower?igsh=cHc3dnc2bDlhNjRx",
    "https://www.snapchat.com/add/alepower?share_id=QO633sb7P5M",
    "https://bere.al/alepower",
    "https://tvtime.com/r/34SY4"
  ]

  return (
      <div className='mt-4 px-6 py-4 bg-stone-100 dark:bg-stone-600'>
          <div className='flex justify-between items-center gap-6 sm:justify-start sm:gap-20'> 
            <div className='font-bold text-lg sm:text-5xl dark:text-white'>SOCIALS</div>
            {socials.map((item, index) => (<SocialItem social={item} url={social_urls[index]} src={social_srcs[index]} />))}
          </div>
      </div>
  )
}
