import React from 'react'

const Bubble = ({children}) =>{
    return(
        <div className='w-full rounded-xl px-4 py-3 bg-stone-200 dark:bg-stone-500'>
                {children}
        </div>
    )
}

const InfoSection = ({title, children}) =>{
    return(
        <div className='w-full bg-stone-100 dark:bg-stone-600'>
            <div className='w-full px-6 pb-4 mt-4'>
                <div className='flex justify-center py-2 font-bold sm:text-4xl dark:text-white'>{title}</div>                
                {children}
            </div>
        </div>
    )
}

const ListSection = ({title, description, children}) =>{
    return(
        <div className='mt-4 bg-stone-100 dark:bg-stone-600'>
            <div className='w-full px-6 py-2 pt-4'>
                <div className='font-bold text-lg sm:text-5xl dark:text-white'>{title}</div>
                <div className='text-sm text-stone-500 sm:text-2xl dark:text-stone-200'>{description}</div>
                {children}
            </div>
        </div>
    )
}

export const Common = () => {
  return (
    <div>Common</div>
  )
}

export {InfoSection, ListSection, Bubble};