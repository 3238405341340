import React, { useState } from "react"
import { ListSection } from '../Common'

const ProjectItem = ({title, description, children}) =>{
    return(
        <div className='flex flex-col'>
            <div className='rounded-xl px-4 py-2 my-2 bg-stone-200 dark:bg-stone-400'>
                <div className='font-bold sm:text-3xl dark:text-white'>{title}</div>

                <div className='py-2 flex'>

                        <div className='flex items-center bg-white rounded-xl justify-center w-1/3 aspect-square'>
                            🚧 🚧 🚧
                        </div>

                        <div className='w-2/3 px-4 text-xs sm:text-3xl dark:text-white'>
                            {description}
                            {children && (<div>{children}</div>)}
                        </div>
                        
                    </div>
            </div>
        </div>
    )
}

export const Projects = () => {
    const title = "PROJECTS"
    const description = "Some of the side projects that I worked on:"

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => setIsExpanded(!isExpanded);

    const project_titles = ['PatchCore Transformed', 'This Website']
    const project_descriptions = [
        "This project was part of my master's thesis.\nIt focused on integrating Vision Transformers (ViTs) into an anomaly detection computer vision algorithm (PatchCore) as an alternative to traditional Convolutional Neural Networks (CNNs).",
        "The webpage that you're currently on is also a personal project of mine, created to help me learn how to build a website from scratch."
    ]
    const project_extended = [
        "Experiments were conducted on 2 datasets, addressing key challenges in integrating ViTs PatchCore and suggesting improvements to its algorithm to enhance performance and efficiency ",
        "The goal is to maximize its design and functionality, giving me hands-on experience in all aspects of development from design and layout to front-end, back-end, and deployment."
    ]

    return (
        <ListSection title={title} description={description}>

            {project_titles.map((item, index) => (
                <ProjectItem title={item} description={project_descriptions[index]}>
                    <span className={isExpanded ? '' : 'hidden'}>
                        {project_extended[index]}
                    </span>

                    <div className="flex justify-end">
                        <button onClick={toggleReadMore} className="italic text-stone-600 dark:text-stone-200">
                            {isExpanded ? "Show Less" : "Show More"}
                        </button>
                    </div>    
                </ProjectItem>
            ))}

        </ListSection>
  )
}
