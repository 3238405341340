import React from 'react'
import {InfoSection, Bubble} from '../../components/Common'

const SkillItem = (props) => {
    return(
        <Bubble>
            <div className='font-bold sm:text-2xl dark:text-white'>{props.title}</div> 
            <ul className='text-left sm:text-xl dark:text-white'>
                {props.skills.map((item, index) => (<li className='mt-2 sm:my-6 sm:mx-4' key={index}>✓ {item} </li>))}
            </ul>
        </Bubble>
    )
}

export const Skills = () => {
    let titles = ['A.I.', 'Cloud', 'Web', 'Languages']
    let skills = [
        ['Pytorch', 'TensorFlow', 'HuggingFace', 'SciKit-Learn'], 
        ['Docker', 'Kubernetes', 'Helm', 'ArgoCD'],
        ['React', 'Tailwind'],
        ['C', 'Java', 'Python']
    ]

  return (
    <InfoSection title='Skills'>
        <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 text-center text-xs'>
            {titles.map((item, index) => (<SkillItem title={item} skills={skills[index]}/>))}
        </div>
    </InfoSection>
  )
}
