import React from 'react'
import { ListSection } from '../Common'

const DanceItem = (props) =>{
  return(
      <div className='aspect-video container flex justify-center my-2 p-4 sm:py-8 bg-stone-200 dark:bg-stone-500'>
        <iframe className='container aspect-video' src={props.dance} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
  )
}

export const Dance = () => {
  const title = "DANCE"
  const description = "On my free time i like to dance:"
  let dances = [
    "https://www.youtube.com/embed/pkytMyLbysQ?si=ZputRPI_SibEvtfl",
    "https://www.youtube.com/embed/HmcR2rXFQGM?si=na1BSDfCjW3xPt9-",
    "https://www.youtube.com/embed/R6QLBK2Raik?si=7B9cIScWEmb8dgzx"
  ]

  return (
    <ListSection title={title} description={description}>
      <div className='flex flex-col justify-between mt-2 sm:flex-row sm:gap-8'>
        {dances.map((item, index) => (<DanceItem dance={item}/>))}
      </div>
    </ListSection>
  )
}