import React from 'react'

export const Footer = () => {
  return (
    <div className='mt-4 flex flex-col-3 justify-between items-center w-full h-2 p-6 bg-stone-400 dark:bg-stone-800'>
        <div className='text-stone-400 dark:text-stone-800'></div>
        <div className='text-white text-xs font-light '>Developed by @notalepower</div>
        <div className='text-stone-400 dark:text-stone-800'></div>
    </div>
  )
}
