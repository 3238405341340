import React, { useEffect, useState } from 'react';
import { Bubble } from '../Common'

export const Workout = () => {

  const [calories, setCalories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCalories = async () => {
      try {
        const targetUrl = 'https://worker-testing.alessandro-david2.workers.dev/'
        const response = await fetch(targetUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        var calories = result['summary']['caloriesOut']
        setCalories(calories);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCalories();
  }, []);

  return (
    <Bubble>
        <div className='h-full flex flex-col justify-between'>
            <div className='text-sm sm:text-2xl dark:text-stone-100'>Calories burned today:</div>

            <div className='text-stone-800 sm:text-2xl dark:text-white'>
                {loading && <div className='text-xl font-bold text-center mt-2 sm:text-4xl'>Loading...</div>}
                {error && <div className='text-xl font-bold text-center mt-2 sm:text-4xl'>Error: {error.message}</div>}
                {calories && <div className='text-xl font-bold text-center mt-2 sm:text-4xl'>{calories}</div>}
                  
            </div>

            <div></div>

        </div>
    </Bubble>
  )
}
