import React from 'react'
import { Home } from "./components/Home";
import { About } from "./components/About";
import { ThemeToggle } from './components/ThemeToggle'

import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

export const Navbar = () => {
  return (
    <Router>
      <div className='fixed w-full h-auto flex items-center px-6 py-4 mb-16 bg-stone-600 dark:bg-stone-800 text-white'>
          {/* LOGO */}
          <div className='w-1/2 font-bold text-sm sm:text-xl xl:text-3xl'>
              ALESSANDRO DAVID
          </div>

          <div className='w-1/2'>
              <ul className='nav flex justify-evenly items-center text-xs min-[500px]:text-sm sm:text-xl'>
                  <li> 
                    <NavLink to="/" className={({ isActive }) => isActive ? 'border-b-2': 'border-b-0'}>
                      Home
                    </NavLink> 
                  </li>
                    
                  <li> 
                    <NavLink to="/about" className={({ isActive }) => isActive ? 'border-b-2': 'border-b-0'}
                      >About
                    </NavLink> 
                  </li>
                  
                  <li className=''>
                    <ThemeToggle />
                    </li>
              </ul>
          </div>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>

    </Router>
  )
}
