import React from 'react';
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

function App() {
  return (
    <div className="dark:bg-stone-700">
      <Navbar />
      <Footer />
    </div>
  );
}

export default App;
