import React from 'react'
import { useState, useEffect } from 'react'
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

export const ThemeToggle = () => {
    const [darkMode, setDarkMode] = useState(false)
    
    // Al caricamento iniziale, controlla se la modalità dark è già attiva 
    // (ad esempio memorizzata nel localStorage o nelle preferenze del sistema)
    useEffect(() => {
        const root = window.document.documentElement;
        const initialTheme = localStorage.getItem('theme');
        
        if (initialTheme === 'dark' || (!initialTheme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            root.classList.add('dark');
            setDarkMode(true);
        }
    }, []);

    // Funzione per gestire il cambio di tema
    const toggleTheme = () => {
        const root = window.document.documentElement;
    
        if (darkMode) root.classList.remove('dark');
        else root.classList.add('dark');
    
        localStorage.setItem('theme', darkMode ? 'light' : 'dark');
        setDarkMode(!darkMode);
    };
  
    return (
        <div className='h-full'>
            <button onClick={toggleTheme} className="flex justify-center items-center bg-white text-stone-600 dark:text-stone-800 aspect-square rounded-full">
                {darkMode ? <LightModeIcon sx={{ fontSize: 18 }}/> : <DarkModeIcon sx={{ fontSize: 18 }} />}
            </button>
        </div>
    )
}
