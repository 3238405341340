import React from 'react'
import photo from '../../assets/Z2.jpg'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Hero = () => {
  return (
    <div id="hero" className='flex pt-16 sm:pt-20 pb-4 px-6 bg-stone-100 dark:bg-stone-600 dark:text-white'>
        
        <div className='flex flex-col justify-center w-1/2 pr-2'> 
            <div className='font-extrabold text-lg sm:text-6xl'>
              Hello 👋🏽 ! I'm Ale.
            </div>
            <div className='text-xs sm:text-3xl dark:text-stone-200'>
              I graduated from the <span className='font-bold dark:text-white'>University of Bologna</span>, with a Bachelor's degree in <span className='font-bold dark:text-white'>Computer Engineering</span>.
              My passion for technology then inspired me to pursue a Master's degree in <span className='font-bold dark:text-white'>Artificial Intelligence</span>.
            </div>
        </div>
        
        {/* PHOTO */}
        <div className='w-1/2 flex flex-col justify-center items-center p-2'>
          <div className='bg-opache sm:w-5/6'>
            <img src={photo} className='rounded-full min-w-full aspect-square object-cover' alt='Foto Profilo'/>
          </div>
        </div>

    </div>
  )
}
